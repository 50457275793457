import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import CTASection from "../components/misc/cta-section";
import Seo from "../components/misc/seo";
import { markdownCleaner } from "../utils/markdownCleaner";
import { useAddThis } from "../hooks/useAddThis";

const MiniResource = ({ data, pageContext }) => {
    const menus = pageContext.menus?.items;
    const page = data.contentfulMiniResource;
    const pageTitle = page.page_title ? page.page_title : page.name;
    const content = markdownCleaner(page.content.content);
    const bodyClasses = `${pageContext.type} resources_${page.slug}`;

    useAddThis();

    return (
        <Layout menus={menus} headerType={`page`} pageHeading={pageTitle}>
            <Seo
                title={pageTitle}
                description={page.overview}
                slug={`/resources/${page.slug}`}
                bodyClass={bodyClasses}
            />

            <section
                className="st-section st-section--narrow mini-resource"
                dangerouslySetInnerHTML={{ __html: content }}
            />
            <CTASection
                heading={`Give Stitch a try, on us`}
                subheading={`Stitch streams all of your data directly to your analytics warehouse.`}
                dualCTA={true}
                containerClasses={`st-section--lowlight st-section--no-margin`}
            />
        </Layout>
    );
};

export default MiniResource;

export const query = graphql`
    query($slug: String!) {
        contentfulMiniResource(slug: { eq: $slug }) {
            contentful_id
            id
            name
            page_title
            overview
            slug
            content {
                content
            }
            resource_category {
                name
            }
        }
    }
`;
